<template>
  <div class="Failure">
    <FailureScreen />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FailureScreen from '@/components/FailureScreen.vue';

export default defineComponent({
  name: 'FailurePage',
  components: {
    FailureScreen,
  },
});
</script>
