<template>
  <div class="main-visual">
    <div class="bg-color"></div>
    <div class="neon-card">
      <div class="neon-card-center">
        <h1 class="mb-32 mt-0 text-center">
          <img
            width="161"
            height="42"
            :src="state.clientIconUrl"
            alt="Target and COMPANY logo"
          />
        </h1>
        <h2 class="mb-24 mt-0 text-bold text-center">{{ state.consentTitleText }}</h2>
        <p class="text-body">
          {{ state.consentDisplayText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'SuccessScreen',
  setup() {
    const state = reactive({
      consentTitleText: '',
      consentDisplayText: '',
      clientName: 'Microsoft Teams', // TODO: BOT-746: Resolve dynamically
      clientIconUrl: require('../assets/icons/teams-and-company-logo.svg'), // TODO: Resolve dynamically & Separate by Target icon and COMPANY icons
    });

    const closeWindowBtn = () => self.window.close();
    const autoCloseWindow = () => window.setTimeout(closeWindowBtn, 5000);
    autoCloseWindow();

    state.consentTitleText = `${state.clientName} との連携が完了しました`
    state.consentDisplayText = `このウィンドウは 5 秒後に自動的に閉じます。自動的に閉じない場合は手動で閉じてください。`

    return {
      state,
      closeWindowBtn,
      autoCloseWindow,
    };
  },
});
</script>

<style lang="scss" scoped>
@media screen and (min-width: 510px) {
  .main-visual {
    align-items: center;
    padding: 16px;
    display: flex;
    height: 100%;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .bg-color {
    background: linear-gradient(
      45deg,
      rgba(0, 91, 172, 1),
      rgba(94, 194, 198, 1) 100%
    );
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  .neon {
    &-card {
      max-height: 100%;
      transform-origin: center center;
      overflow-y: auto;
      &-center {
        background-color: #ffffff;
        border-radius: 4px;
        height: auto;
        max-width: 524px;
        padding: 48px;
      }
      &-top {
        background-color: #ffffff;
        border-radius: 4px;
        height: auto;
        padding: 48px;
        max-width: 524px;
      }
    }
  }
  .text {
    &-bold {
      font-size: 18px;
      font-weight: bold;
    }
    &-body {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 509px) {
  .main-visual {
    padding: 24px;
  }
  .neon {
    &-card {
      margin: 24px 0 0 0;
      &-top {
        background-color: #ffffff;
        height: 100%;
        width: 100%;
      }
    }
  }
  .text {
    &-bold {
      font-size: 16px;
      font-weight: bold;
    }
    &-body {
      font-size: 14px;
    }
  }
}
</style>
