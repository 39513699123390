<template>
  <router-view :style="neonStyle"/>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import 'normalize.css';
import { baseColor, companyTopNav, btnColor } from './theme/neon';

export default defineComponent({
  name: 'App',
  setup() {
    const neonStyle = computed(() => ({
      '--baseColor100': baseColor.black[100],
      '--companyTopNav1': companyTopNav.bgColor.blue1,
      '--companyTopNav2': companyTopNav.bgColor.blue2,
      '--btnColorBgDefault': btnColor.bgColor.colorDefault,
      '--btnColorBgDefaultHover': btnColor.bgColor.colorDefaultHover,
      '--btnColorBgPrimary': btnColor.bgColor.colorPrimary,
      '--btnColorBgPrimaryHover': btnColor.bgColor.colorPrimaryHover,
      '--btnColorFontWhite': btnColor.fontColor.colorWhite,
      '--btnColorFontBlack': btnColor.fontColor.colorBlack,
    }));

    return {
      neonStyle,
    };
  },
});

</script>

<style lang="scss">
@font-face {
  font-family: "Roboto";
  src: url("././assets/fonts/Roboto-Regular.ttf") format("truetype");
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  color: #323232;
  font-family: "Roboto", "Meiryo";
  font-size: 14px;
  line-height: normal;
}
#nav {
  position: relative;
  z-index: 3;
}
.d-flex-center {
  align-items: center;
  display: flex;
}
.mt {
  &-0 {
    margin-top: 0;
  }
  &-8 {
    margin-top: 8px;
  }
  &-16 {
    margin-top: 16px;
  }
}
.mr {
  &-8 {
    margin-right: 8px;
  }
  &-16 {
    margin-right: 16px;
  }
}
.mb {
  &-0 {
    margin-bottom: 0;
  }
  &-8 {
    margin-bottom: 8px;
  }
  &-16 {
    margin-bottom: 16px;
  }
  &-24 {
    margin-bottom: 24px;
  }
  &-32 {
    margin-bottom: 32px;
  }
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li:last-child {
  margin-bottom: 0 !important;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.neon-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
  cursor: pointer;
  color: var(--btnColorFontBlack);
  background-color: transparent;
  border: 1px solid transparent;
  &:focus {
    box-shadow: none;
  }
  &.default {
    background-color: var(--btnColorBgDefault);
    &:hover {
      background-color: var(--btnColorBgDefaultHover);
    }
  }
  &.primary {
    color: var(--btnColorFontWhite);
    background-color: var(--btnColorBgPrimary);
    &:hover {
      background-color: var(--btnColorBgPrimaryHover);;
    }
  }
}
.text-danger {
  color: #E91E63;
}
</style>
